import React from "react";
import { CmsContent, Container, Layout, PostNavigation, SocialsSharing } from "components";
import { ContactFormBlock, FeaturedPosts, Hero, Loader } from "components/ACF";
import WordPressPreview from "components/WordPressPreview/WordPressPreview";
import { ContainerSpacingEnum, ContainerWidthEnum } from "utils";
import { Post_By_IdQuery, WpCategory } from "codegen";
import classNames from "classnames";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

interface BlogPostTemplate {
  data: Post_By_IdQuery;
  categories: {
    nodes: WpCategory[];
  };
  containerWidth?: ContainerWidthEnum;
  sectionSpacing?: ContainerSpacingEnum;
}

const BlogPostTemplate = ({
  containerWidth = "layout--small",
  sectionSpacing = "section--small-header",
  data: { previous, next, post, wp, categories },
}: BlogPostTemplate): JSX.Element => {
  const { title, seo, acfLayout, postInformation } = post;
  const description = categories.nodes[0].description;
  const {
    webAppSettings: {
      webAppSettings: { postSharingOptions, globalContactBlock },
    },
  } = wp;

  console.log(postInformation.feedItems);
  return (
    <Layout layoutType="post" pageTitle={title} seo={seo}>
      <>
        <Hero
          data={{
            content: `<span className="typography--h1">Blog</span><p>${description}</p>`,
            compact: true,
            image: categories.nodes[0].featuredImage?.image,
          }}
          location={seo?.breadcrumbs}
        />
        <Container
          className={classNames({
            section: true,
            ["theme--white"]: true,
            [`${containerWidth}`]: containerWidth,
            [`${sectionSpacing}`]: sectionSpacing,
          })}
        >
          <h1 className="c-dark-green">{post.title}</h1>

          {post?.featuredImage && (
            <div className="featured-image">
              <GatsbyImage image={getImage(post?.featuredImage?.node?.localFile)} alt="" />{" "}
            </div>
          )}
        </Container>
        {acfLayout?.layout?.length > 0 ? (
          <Loader
            data={acfLayout.layout.map(({ ...layout }) => ({
              ...layout,
            }))}
            location={seo?.breadcrumbs}
          />
        ) : (
          <>
            {/* // <div className="post"> */}
            {post.content && (
              <Container
                className={classNames({
                  section: true,
                  ["theme--white"]: true,
                  [`${containerWidth}`]: containerWidth,
                  [`${sectionSpacing}`]: sectionSpacing,
                })}
              >
                {/* Using CmsContent here incase we're using shortcodes */}
                <CmsContent content={post.content} />
              </Container>
            )}
            {/* </div> */}
          </>
        )}
        {/* {postSharingOptions && <SocialsSharing socialItems={postSharingOptions} />} */}
        <PostNavigation prevLink={previous} nextLink={next} backLink="/blog/" backLinkText="Back to blog" />
        {postInformation?.feedItems && <FeaturedPosts data={postInformation} />}
        {globalContactBlock && globalContactBlock.row && <ContactFormBlock data={globalContactBlock} />}
      </>
    </Layout>
  );
};

export default WordPressPreview({ postType: "posts" })(BlogPostTemplate);

export const PostByIdQuery = graphql`
  query POST_BY_ID($id: String!, $previousPostId: String, $nextPostId: String) {
    post: wpPost(id: { eq: $id }) {
      ...PostFields
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
    categories: allWpCategory(filter: { count: { gt: 0 } }) {
      nodes {
        id
        description
        featuredImage {
          image {
            id
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED, width: 1800)
              }
            }
          }
        }
      }
    }
    wp {
      webAppSettings {
        webAppSettings {
          postSharingOptions
          globalContactBlock {
            row {
              contentType
              formId
              content
            }
          }
        }
      }
    }
  }
`;
